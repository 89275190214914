import * as React from "react";
import { Layout, News, PageTitle } from "../components";

import Seo from "../components/Seo";

const NewsPage = () => {
  return (
    <Layout>
      <Seo title="BLOG - ブログ・お知らせ" />
      <PageTitle title="BLOG" subTitle="ブログ・お知らせ" />
      <News />
    </Layout>
  );
};

export default NewsPage;
